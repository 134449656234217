var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aFormItem',{staticClass:"hf-wrapper hf-radio-wrapper"},[(_vm.label)?_c('label',{staticClass:"label",attrs:{"for":""}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_vm._v(" "),(_vm.description)?_c('div',{staticClass:"description"},[_vm._v(_vm._s(_vm.description))]):_vm._e(),_vm._v(" "),_c('aRadioGroup',{directives:[{name:"decorator",rawName:"v-decorator",value:([
      _vm.fieldKey,
      {
        initialValue: _vm.initialValue,
        rules: _vm.validationRules,
      },
    ]),expression:"[\n      fieldKey,\n      {\n        initialValue,\n        rules: validationRules,\n      },\n    ]"}],staticClass:"hf-radio",class:_vm.fieldClass,attrs:{"disabled":_vm.disabled}},_vm._l((_vm.options),function({ value, label, disabled },i){return _c('aRadio',{key:i,staticClass:"f13",attrs:{"disabled":disabled,"value":value}},[_vm._v("\n      "+_vm._s(label)+"\n    ")])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }