<template>
  <aFormItem class="hf-wrapper hf-radio-wrapper">
    <label class="label" v-if="label" for>{{ label }}</label>
    <div class="description" v-if="description">{{ description }}</div>
    <aRadioGroup
      class="hf-radio"
      :class="fieldClass"
      :disabled="disabled"
      v-decorator="[
        fieldKey,
        {
          initialValue,
          rules: validationRules,
        },
      ]"
    >
      <aRadio
        v-for="({ value, label, disabled }, i) in options"
        :key="i"
        :disabled="disabled"
        :value="value"
        class="f13"
      >
        {{ label }}
      </aRadio>
    </aRadioGroup>
  </aFormItem>
</template>

<script>
export default {
  name: "HayaRadioField",
  inheritAttrs: false,
  props: {
    label: String,
    type: String,
    description: String,
    fieldKey: String,
    fieldClass: String,
    requiredMessage: String,
    required: Boolean,
    initialValue: String,
    options: Array,
    form: Object,
    disabled: Boolean,
  },
  data() {
    return {
      validationRules: [],
    };
  },
  beforeMount() {
    if (this.required) {
      this.validationRules.push({
        required: true,
        message: this.requiredMessage ? this.requiredMessage : "Obrigatório",
      });
    }
  },
};
</script>

<style lang="sass">
.hf-radio-wrapper
  .ant-form-explain
    font-size: 9px !important
    top:-11px
    position: relative
</style>

<style lang="sass" scoped>
.hf-wrapper
  label
    position: relative !important
    opacity: 1 !important
    width: auto !important
    top: 0 !important
  label.ant-radio-wrapper
    color: #666 !important
  .label
    font-size: 10px
    font-weight: 500
    color: #ccc
    line-height: 1
    text-overflow: ellipsis
    width: 100%
    white-space: nowrap
    margin-top: -10px
    margin-bottom: 2px
    display: block
  .description
    font-size: 9px
    line-height: 1.2
    margin: 8px  0 3px
    font-weight: 500
    border-left: 2px solid #ddd
    padding-left: 7px
</style>
